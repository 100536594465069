import MicroModal from 'micromodal';

/*---------------------------------
  共通のJS
---------------------------------*/

$(document).ready(function() {
//ダイアログを開く
  MicroModal.init();

//ダイアログを閉じる
  $('.c-btn__cancel, #dialogOverlay').on('click', function() {
    $('body').removeClass('scrollLock');
  });

//トーストを閉じる
  $('.c-toast__close').on('click', function(){
    $('#toast').hide();
  });
});

window.openWindow = function(path) {
  window.open(path, "win", "width=500, height=400");
}

//画像アップロード
$(document).on("change",'#file',function (e) {
  //ファイルオブジェクトを取得する
  var file = e.target.files[0];
  var reader = new FileReader();

  //画像でない場合はアラート
  if (file.type.indexOf("image") < 0) {
    alert("画像ファイルを指定してください。");
    return false;
  }

  //アップロードした画像を設定する
  reader.onload = (function (file) {
    return function (e) {
      $("#user_icon").attr("src", e.target.result);
    };
  })(file);
  reader.readAsDataURL(file);
});

$('#deletePhoto').on('click', function () {
  $("#user_icon").attr('src', 'images/icon_default.svg');
  $("#file").val('');
});



